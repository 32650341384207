<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_format_impartation',
            title: $t('enable_grouper'),
            type: 'warning',
            style: 'width: 28%; min-width: 1000px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; margin-right: 40px; padding-bottom: 15px;">
                <div class="T15_b" style="padding-left: 20px;">
                    {{$t('preparing_to_enable') + ' ' + info.code + ' ' + info.name}}
                </div>
                <div class="T15" style="padding-left: 20px;" v-html="info.description"></div>
            </div>
            <div class="gh-content-global">
                <form id="form_popup_format_impartation">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label" :class="'T15'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('item') + ':'}}</label>
                        <GhChecks
                            :datalabel="{text:$t('full'),style:'',class:'',id:''}"
                            :datainput="{
                                required: true,
                                selected: false,
                                type: 'radio',
                                id:'',
                                value: 'full',
                                name: 'grouper[item]'
                            }"
                            ref="RefItemFull"
                            @click="formatItem('full'); checkImpartation('full')"
                        />
                        <GhChecks
                            :datalabel="{text: $t('dependent'), style: '', class: '', id: ''}"
                            :datainput="{
                                required: true,
                                selected: true,
                                type: 'radio',
                                id: '',
                                value: 'dependent',
                                name: 'grouper[item]'
                            }"
                            @click="formatItem('dependent'); checkImpartation('dependent')"
                        />
                    </div>
                    <div class="gh-row-content no-min-width" v-if="showImpartation">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_grouper_impartation',
                                class: 'T15_b',
                                subtitle: $t('select_impartation') + ':',
                                text: $t('impartation') + '*',
                                label: $t('impartation'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            }"
                            :datainput="{
                                id: 'grouper_impartation',
                                name: 'grouper[impartation][]'
                            }"
                            ref="RefImpartation"
                            :selected_input="[]"
                            :feed="getFeeds.feed_impartation"
                            @accept="checkImpartation('RefImpartation')"
                        />
                    </div>
                    <div class="gh-row-content" :key="'IMPARTITIONS' + refreshImpartitions" v-if="show.checks !== undefined && show.checks.length !== 0" style="margin-left: 160px;">
                        <template v-for="(checks, keyChecks) in show.checks">
                            <GhChecks
                                :key="'CHECKS' + keyChecks"
                                :datalabel="{text: $t(checks),style:'',class:'',id:''}"
                                :datainput="{
                                    required: false,
                                    selected: show.checked.includes(keyChecks),
                                    type: 'checkbox',
                                    id: '',
                                    value: keyChecks,
                                    name: 'grouper[checks][]'
                                }"
                            />
                        </template>
                    </div>
                    <div class="gh-row-content" v-if="show.template_report" style="margin-left: 160px;">
                        <GhChecks
                            :datalabel="{text: $t('default_template'), style:'', class:'', id:''}"
                            :datainput="{
                                required: false,
                                selected: false,
                                type: 'checkbox',
                                id:'',
                                value: 1,
                                name: 'grouper[default_template]'
                            }"
                            ref="RefDefaultTemplate"
                            @click="setDefaultTemplate"
                        />
                    </div>
                    <div class="gh-row-content" v-if="show.template_report && !defaultTemplate">
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('template_report') + '*'}}</label>
                        <GhEasyUpload
                            :extrablock="{
                                id: 'grouper_easy_upload_template_report',
                                class: '',
                                style: 'flex-direction: row !important;',
                                name_hidden: 'grouper[attach][template_report]',
                                id_hidden: 'grouper_attach_template_report',
                                icon_attach: require('../../../../assets/gh_new_dessign/attach.svg'),
                                icon_trash: require('../../../../assets/gh_new_dessign/trash.svg'),
                            }"
                            :datalabel="{
                                id: 'label_grouper_easy_upload_final_template_report',
                                style: ''
                            }"
                            :datainput="{
                                text: $t('attach_template_report'),
                                label_required: $t('template_report'),
                                accept: '.tex',
                                show_image: false,
                                delete: true,
                                href: 'easyUpload',
                                downloadHref: 'easyDownload',
                                file_name: '',
                                file_path: '',
                                file_download: '',
                                required: true
                            }"
                        />
                    </div>
                    <div class="gh-row-content" v-if="show.model_report">
                        <GhAction
                            style="margin-right: 12px;"
                            :dataction="{
                                id: 'download_model_report',
                                text: $t('download_model_report'),
                                icon: require('@/assets/gh_new_dessign/descargar.svg'),
                            }"
                            @click="$store.dispatch('easyDownload', {url: url}, {root: true})"
                        />
                        <GhEasyUpload
                            :extrablock="{
                                id: 'grouper_easy_upload_report',
                                class: '',
                                style: 'flex-direction: row !important;',
                                name_hidden: 'grouper[attach][report]',
                                id_hidden: 'grouper_attach_report',
                                icon_attach: require('../../../../assets/gh_new_dessign/attach.svg'),
                                icon_trash: require('../../../../assets/gh_new_dessign/trash.svg'),
                            }"
                            :datalabel="{
                                id: 'label_grouper_easy_upload_report',
                                style: ''
                            }"
                            :datainput="{
                                text: $t('attach_model_report') + '*',
                                show_image: false,
                                delete: true,
                                href: 'easyUpload',
                                downloadHref: 'easyDownload',
                                file_name: '',
                                file_path: '',
                                file_download: '',
                                required: true
                            }"
                        />
                    </div>
                    <div class="gh-row-content" v-if="show.value">
                        <GhInputTextField
                            :datalabel="{
                                text: $t('price') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            }"
                            :datainput="{
                                required: true,
                                id: 'grouper_price',
                                name: 'grouper[price]',
                                style: 'width: 80px',
                                value: '',
                                limitNumMax: 99999,
                                type: 'int'
                            }"
                        />
                        <label class="T15_b" style="margin-left: 10px;margin-top: 2px;">€</label>
                    </div>
                    <div class="gh-row-content no-min-width" v-if="show.basic_data">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_grouper_activity',
                                class: 'T15_b',
                                subtitle: $t('select_activity_sector') + ':',
                                text: $t('activity_sector') + '*',
                                label: $t('activity_sector'),
                                type: 'checkbox',
                                columns: 3,
                                required: true,
                                label_required: $t('activity_sector'),
                                style: '',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_num_employee',
                                name: 'grouper[extra_info][activity][]'
                            }"
                            :feed="getFeeds.feed_activity"
                            :selected_input="[]"
                            ref="RefActivity"
                        />
                    </div>
                    <div class="gh-row-content no-min-width" v-if="show.basic_data">
                        <GhDataSelector
                            :datablock="{
                                id: 'num_employee',
                                class: 'T15_b',
                                subtitle: $t('select_number_employees') + ':',
                                text: $t('number_employees') + '*',
                                label: $t('number_employees'),
                                type: 'checkbox',
                                columns: 3,
                                required: true,
                                label_required: $t('number_employees'),
                                style: 'margin-left: -5px;',
                                popupStyle: 'min-width: 900px;width: 55%;'
                            }"
                            :datainput="{
                                id: 'value_num_employee',
                                name: 'grouper[extra_info][num_employee][]'
                            }"
                            :feed="getFeeds.feed_num_employers"
                            :selected_input="[]"
                            ref="RefEmployee"
                        />
                    </div>
                    <div class="gh-row-content no-min-width" v-if="show.basic_data">
                        <GhDataSelector
                            :datablock="{
                                id: 'label_grouper_country',
                                class: 'T15_b',
                                subtitle: $t('select_country') + ':',
                                text: $t('country') + '*',
                                label: $t('country'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            }"
                            :datainput="{
                                id: 'grouper_country',
                                name: 'grouper[extra_info][country][]'
                            }"
                            :selected_input="[]"
                            :feed="getFeeds.feed_country"
                            ref="RefCountry"
                        />
                    </div>
                    <div class="gh-row-content no-min-width" v-if="show.map" style="margin-left: 160px;">
                        <GhChecks
                            :datalabel="{text:$t('numeric_map'),style:'',class:'',id:''}"
                            :datainput="{
                                required: false,
                                selected: false,
                                type: 'checkbox',
                                id:'',
                                value: 1,
                                name: 'grouper[extra_info][map_numeric]'
                            }"
                        />
                    </div>
                </form>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button_format_impartation_accept',
                    text: $t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="saveGrouper('enable');"
            />
            <GhButton
                :datainput="{
                    id: 'button_format_impartation_close',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_format_impartation');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapState} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhAction from "fe-gh-action-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    export default {
        name: 'format_impartation',
        props: ['info'],
        components:{
            GhEasyUpload,
            GhAction,
            GhInputTextField,
            GhDataSelector,
            GhChecks,
            GhPopUp,
            GhButton
        },
        computed:{
            ...mapState(['getFeeds']),
        },
        data(){
            return {
                showImpartation: false,
                show: {
                    template_report: false,
                    model_report: false,
                    value: false,
                    checks: [],
                    checked: [],
                    basic_data: false,
                    map: false,
                },
                refreshImpartitions: 0,
                defaultTemplate: false,
                url: ''
            }
        },
        async beforeMount(){
            const token = localStorage.getItem("token");
            if (token) {
                await this.$store.dispatch('getCountry', '', {root: true});
                await this.$store.dispatch('getNumEmployers', '', {root: true});
                await this.$store.dispatch('getActivity', '', {root: true});
            }
        },
        methods:{
            setDefaultTemplate(){
                this.defaultTemplate = this.$refs['RefDefaultTemplate'].selected;
            },
            formatItem(format){
                this.showImpartation = format === 'full';
            },
            async checkImpartation(type){
                if(type === 'dependent'){
                    this.show.template_report = false;
                    this.show.model_report = false;
                    this.show.value = false;
                    this.show.checks = [];
                    this.show.checked = [];
                    this.show.basic_data = false;
                    this.show.map = false;
                    this.url = '';
                }else if(type === 'RefImpartation'){
                    if(this.$refs.RefItemFull.selected) {
                        await this.$store.dispatch('getImpartationType', {
                            items: Object.keys(this.$refs.RefImpartation.checkdata)
                        }, {root: true}).then((response) => {
                            if(response.status === 200) {
                                this.show.template_report = response.data.data.template_report;
                                this.show.model_report = response.data.data.model_report;
                                this.show.value = response.data.data.value;
                                this.show.checks = response.data.data.checks === undefined ? [] : response.data.data.checks;
                                this.show.checked = response.data.data.checked === undefined ? [] : response.data.data.checked;
                                this.show.basic_data = true;
                                this.show.map = true;

                                if(response.data.data.model_report){
                                    this.$store.dispatch('getModelReport', {id: this.$root._route.params.id}, {root: true}).then((response) => {
                                        if(response.status === 200) {
                                            this.url = response.data.data;
                                        }
                                    });
                                }else{
                                    this.url = '';
                                }
                            }
                        });
                    }else{
                        this.show.template_report = false;
                        this.show.model_report = false;
                        this.show.value = false;
                        this.show.checks = [];
                        this.show.checked = [];
                        this.show.basic_data = false;
                        this.show.map = false;
                        this.url = '';
                    }
                }

                this.refreshImpartitions++
            },
            saveGrouper(type){
                if (this.Global.checkRequired('form_popup_format_impartation') === 'OK') {
                    if (this.$root._route.params.parent_grouper_id !== undefined) {
                        localStorage.setItem('is_saved_element', true);
                    }
                    const formData = new FormData(document.getElementById('form_grouper'));
                    const formDataPopup = new FormData(document.getElementById('form_popup_format_impartation'));
                    this.$store.dispatch('setCreationGrouperData', {
                        formData: [...formData],
                        formDataPopup: [...formDataPopup],
                        type: type,
                        id: this.$root._route.params.id,
                        grouper: this.$root._route.params.grouper,
                        type_url: this.$root._route.params.type,
                        version: this.$root._route.params.version
                    }, {root: true}).then((response) => {
                        if(response.status === 467){
                            let activity = Object.values(this.$refs['RefActivity'].checkdata).join(', ');
                            let employee = Object.values(this.$refs['RefEmployee'].checkdata).join(', ');
                            let country = Object.values(this.$refs['RefCountry'].checkdata).join(', ');

                            this.$parent.$parent.$parent.subTitle = this.$t('impartition_warning_text');
                            this.$parent.$parent.$parent.bodyText =
                                '<div class="gh-row-content" style="margin-left: 5px;">' +
                                '<label class="gh_text_field-label T15">' + this.$t('for_this_sector') + '<span class="T15_b">' + ' (' + activity + ') ' + '</span> ' +
                                this.$t('with_employee').toLowerCase() + ' ' + '<span class="T15_b">(' + employee + ')</span> ' + this.$t('and') + ' ' + this.$t('country').toLowerCase() +
                                ' <span class="T15_b">(' + country + ')</span> ' + this.$t('exist_start_autoevaluation').toLowerCase() + ' <span class="T15_b">(' + this.info.code + ' ' + this.info.name + ')</span>' +
                                '</label>' +
                                '</div>' +
                                '<div class="gh-row-content" style="margin-left: 5px;">' +
                                '<label class="gh_text_field-label T15">' + this.$t('fix_selection') + '</label>' +
                                '</div>';

                            this.Global.openPopUp('popup_warning_content_text');
                        }
                    });
                }
            },
        }
    }
</script>

<style>
    #label_grouper_country_selector_label,#label_grouper_activity_selector_label{
        width: 150px;
        text-align: right;
    }
</style>