import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import router from "@/routes/router";
import Global from "@/store/Global";

Vue.use(vuex);

export default {
    state: {
        data: [],
        refresh: 0,
        leadManager: [],
        advisoryEvaluator: [],
        practiceEvaluator: [],
        refreshAssignationBase: false,
        refreshPreferentialEvaluators: false,
        refreshLeadManager: false,
        refreshAdvisoryEvaluator: false,
        refreshPracticeEvaluator: false,
        refreshRefreshPracticeEvaluator: false,
    },
    mutations: {
        loadAssignationBase(state, payload){
            state.refreshAssignationBase = !state.refreshAssignationBase;
            state.data = payload;
        },
        loadRefreshAssignationBase(state){
            state.refreshRefreshPracticeEvaluator = !state.refreshRefreshPracticeEvaluator;
            state.refresh++;
        },
        loadLeadManager(state, payload){
            state.refreshLeadManager = !state.refreshLeadManager;
            state.leadManager = payload;
        },
        loadAdvisoryEvaluator(state, payload){
            state.refreshAdvisoryEvaluator = !state.refreshAdvisoryEvaluator;
            state.advisoryEvaluator = payload;
        },
        loadPracticeEvaluator(state, payload){
            state.refreshPracticeEvaluator = !state.refreshPracticeEvaluator;
            state.practiceEvaluator = payload;
        },
    },
    getters: {
        getterAssignationBase(state){
            const dummy = state.refreshAssignationBase; // eslint-disable-line no-unused-vars
            return state.data;
        },
        getterRefreshAssignationBase(state){
            const dummy = state.refreshRefreshPracticeEvaluator; // eslint-disable-line no-unused-vars
            return state.refresh;
        },
        getterLeadManager(state){
            const dummy = state.refreshLeadManager; // eslint-disable-line no-unused-vars
            return state.leadManager;
        },
        getterAdvisoryEvaluator(state){
            const dummy = state.refreshAdvisoryEvaluator; // eslint-disable-line no-unused-vars
            return state.advisoryEvaluator;
        },
        getterPracticeEvaluator(state){
            const dummy = state.refreshPracticeEvaluator; // eslint-disable-line no-unused-vars
            return state.practiceEvaluator;
        }
    },
    actions: {
        async getLeadManager(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/lead"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadLeadManager', response.data.data);
                    }
                }
            )
        },
        async getAdvisoryEvaluator(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/evaluator"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadAdvisoryEvaluator', response.data.data);
                    }
                }
            )
        },
        async getPracticeEvaluator(state, item){
            await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/practice"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadPracticeEvaluator', response.data.data);
                    }
                }
            )
        },
        async setAssignTemporalUsers(state, item){
            return await axios({
                method: "post",
                url: API_URL + "assign/users/" + item.program_id + "/" + item.company_id + "/" + item.user_id + "/" + item.type,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    return response;
                }
            )
        },
        async getUsersAssigned(state, item){
            return await axios.get(
                API_URL + "assign/itinerary/" + item.itinerary + "/" + item.id + "/selecteds"
            ).then(
                response => {
                    return response;
                }
            )
        },
        async destroyAssignationBase(state, item){
            return await axios({
                method: "post",
                url: API_URL + "assign/users/" + item.program_id + "/" + item.company_id + "/destroy",
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    return response;
                }
            )
        },
        async getAssignationBase(state, item){
            await axios.get(
                API_URL + "assign/assignation_base/" + item.itinerary + "/" + item.id
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadAssignationBase', response.data.data);
                    }
                }
            )
        },
        async getValuesTypeEvaluation(state, item){
            return await axios.get(
                API_URL + "assign/assignation_base/type_evaluation/" + item.id_type_eval + "/" + item.id_format_eval
            ).then(
                response => {
                    return response;
                }
            )
        },
        async getGlobalScore(state, item){
            return await axios.get(
                API_URL + "assign/assignation_base/global_score/" + item.value_range + "/" + item.percentage
            ).then(
                response => {
                    return response;
                }
            )
        },
        async setAssignationBase(state, item){
            let requestLogin = API_URL + "assign/assignation_base/" + item.itinerary + "/" + item.id + "/" + item.type
            let formData = new FormData();

            item.formData.forEach((item) => {
                formData.append(item[0], item[1]);
            });

            await axios({
                method: "post",
                url: requestLogin,
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                async response => {
                    if(response.status === 200) {
                        if(item.type !== 'save' && item.type !== 'finish' && item.type !== 'recalculate') {
                            router.push({name: 'assignment'});
                        }else{
                            Global.openPopUp(item.type === 'recalculate' ? 'popup_warning_recalculate' : 'popup_warning_save');

                            await this.dispatch('getAssignationBase', {
                                id: item.id,
                                itinerary: item.itinerary
                            });

                            state.commit('loadRefreshAssignationBase');
                        }
                    }
                },
                error => {
                    console.log(error);
                }
            )
        }
    }
}
